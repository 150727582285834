<template>
    <div>
        <div v-if="requestCalls > 0">
            <base-header class="pb-6">
              <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                  <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
              </div>
            </base-header>
            <div class="container-fluid mt--6">
              <div class="card mb-4">
                <div class="card-body text-center">
                  <skeleton-loading></skeleton-loading>
                </div>
              </div>
            </div>
        </div>
        <div v-else>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0">

                        </h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats" show-footer-line="true" id="cards"><!---->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="card-title text-uppercase text-muted mb-0">{{ tt('total_data_raw') }}</h5><span class="h2 font-weight-bold mb-0">{{ dataCard.raw }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
                                            <i class="ni ni-active-40"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <p class="mt-3 mb-0 text-sm ml-1"><span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span><span class="text-nowrap">Since last month</span></p> -->
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats" show-footer-line="true" id="cards"><!---->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="card-title text-uppercase text-muted mb-0">{{ tt('your_target') }}</h5><span class="h2 font-weight-bold mb-0">{{ dataCard.target }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-red">
                                            <i class="ni ni-briefcase-24"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <p class="mt-3 mb-0 text-sm ml-1"><span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span><span class="text-nowrap">Since last month</span></p> -->
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6">
                        <div class="card card-stats" show-footer-line="true" id="cards"><!---->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="card-title text-uppercase text-muted mb-0">{{ tt('my_work') }}</h5><span class="h2 font-weight-bold mb-0">{{ dataCard.work }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-success">
                                            <i class="ni ni-satisfied"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <p class="mt-3 mb-0 text-sm ml-1"><span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span><span class="text-nowrap">Since last month</span></p> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-8">
                                <card type="default" header-classes="bg-transparent" id="chartCat">
                                            <div slot="header" class="row align-items-center">
                                                <div class="col">
                                                    <h6 class="text-light text-uppercase ls-1 mb-1">{{ tt('overview')}}</h6>
                                                    <h5 class="h3 text-white mb-0">{{ tt('perfomance')}}</h5>
                                                </div>
                                                <div class="col">
                                                    <ul class="nav nav-pills justify-content-end">
                                                    <li class="nav-item mr-2 mr-md-0">
                                                        <a class="nav-link py-2 px-3"
                                                        href="#"
                                                        :class="{active: bigLineChart.activeIndex === 1}"
                                                        @click.prevent="graphic('week')">
                                                        <span class="d-none d-md-block">{{ tt('week')}}</span>
                                                        <span class="d-md-none">M</span>
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link py-2 px-3"
                                                        href="#"
                                                        :class="{active: bigLineChart.activeIndex === 0}"
                                                        @click.prevent="graphic('month')">
                                                        <span class="d-none d-md-block">{{ tt('month')}}</span>
                                                        <span class="d-md-none">W</span>
                                                        </a>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <line-chart
                                            :height="350"
                                            ref="bigChart"
                                            :chart-data="bigLineChart.chartData"
                                            :extra-options="bigLineChart.extraOptions"
                                            >
                                            </line-chart>

                                </card>          
                            </div>
                            <div class="col-4">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col">
                                                <h6 class="text-uppercase text-muted ls-1 mb-1"> {{ tt('summary')}} </h6>
                                                <h5 class="h3 mb-0">{{ tt('total_item')}}</h5>
                                            </div>
                                            <!-- <div class="col">
                                                <el-dropdown trigger="click" class="float-right">
                                                    <span class="el-dropdown-link btn btn-dark btn-sm">
                                                        {{ filter }}<i class="el-icon-arrow-down el-icon--right"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                            <el-dropdown-item><base-button size="sm" @click="filter ='Week'">{{ tt('week')}}</base-button></el-dropdown-item>
                                                            <el-dropdown-item><base-button size="sm" @click="filter ='Month'">{{ tt('month')}}</base-button></el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div style="height:400px;;overflow-y: scroll;">
                                        <table class="table">
                                            <thead class="card-header bg-primary">
                                                <tr>
                                                    <th class="text-white">{{ tt('catalog_status')}}</th>
                                                    <th class="text-white text-center">{{ tt('count')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr v-if="onLoading == true">
                                                    <td colspan="2" class="text-center">
                                                        <span><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span> 
                                                    </td>
                                                </tr> -->
                                                <tr v-for="data in dataCard.total_by_cat_status" :key="data.status + Math.random()">
                                                    <td>
                                                        {{ data.status }}
                                                    </td>
                                                    <td class="text-center">
                                                        <div class="fw-bold badge badge-info" v-if="data.count != '0'">{{ data.count }}</div> 
                                                        <div class="text-danger" v-else> {{ data.count }}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as chartConfigs from '@/components/Charts/config';
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';

  export default {
    data() {
      return {
        requestCalls:0,
        dataCard:{
            raw:'',
            work:'',
            total_by_cat_status:[],
            target:''
        },
        bigLineChart: {
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: '',
                data: [],
              }
            ],
            labels: [],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        chartData:[],
      };
    },
    mounted() {
      this.graphic('week');
      this.raw();
      this.work();
      this.target();
      this.catStatus();
    },
    methods: {
      graphic(type){
          this.requestCalls++;
          let filter ;
          let context = this;
          if(type === 'month'){
              filter = 'month';
              this.bigLineChart.activeIndex = 1;
          }else{
              filter = 'week';
              this.bigLineChart.activeIndex = 0;
          }
          Api(context, WorkbenchMaterial.dashboardGraphic({filter_date: filter})).onSuccess((response) => {
              context.chartData = response.data.data;
              context.initChart()
          }).onError((err) => {
              context.chartData = []
          }).onFinish(() =>{
                context.requestCalls--;
          }).call();
      },
      initChart(){
        let datas = [];
        let labels = []

        this.chartData.forEach((item, index) =>{
            datas.push(item.count);
            labels.push(item.date)
        })
        datas.push(60)
        let finalData = {
            datasets: [
                {
                label: 'Performance',
                data: datas,
                backgroundColor: '#f87979'
                }
            ],
            labels: labels,
        };
        this.bigLineChart.chartData = finalData;
      },
      raw(){
          let context = this;

          Api(context, WorkbenchMaterial.dashboardRaw()).onSuccess((response) =>{
              context.dataCard.raw = response.data.data
          }).onError((err) =>{
              context.dataCard.raw = 0;
          }).call()
      },
      target(){
          let context = this;

          Api(context, WorkbenchMaterial.dashboardTarget()).onSuccess((response) =>{
              context.dataCard.target = response.data.data
          }).onError((err) =>{
              context.dataCard.target = 0;
          }).call()
      },
      work(){
          let context = this;

          Api(context, WorkbenchMaterial.dashboardWork()).onSuccess((response) =>{
              context.dataCard.work = response.data.data
          }).onError((err) =>{
              context.dataCard.work = 0;
          }).call()
      },
      catStatus(){
          let context = this;

          Api(context, WorkbenchMaterial.dashboardCatStatus()).onSuccess((response) =>{
              context.dataCard.total_by_cat_status = response.data.data
          }).onError((err) =>{
              context.dataCard.total_by_cat_status = 0;
          }).call()
      },

    },
  };
</script>

<style>
 #cards.card.card-stats{
     min-height: 120px;
 }
 #tableCat .table.table-responsive{
     height: 420px;
     overflow-y:scroll;
 }
</style>